import firebase from 'firebase/app';
import { Asset } from '../assets/Asset';
import { Investor } from '../users/User';
import { SetupFee } from '../assets/Fees';

/**
 * Defining all the statuses a Payment can have.
 */
export enum PaymentStatus {
  Open = 'open',
  Canceled = 'canceled',
  Expired = 'expired',
  Paid = 'paid',
  Refunded = 'refunded',
  Requested = 'requested',
}

/**
 * Different payment gateways/providers we use.
 */
export enum PaymentProvider {
  /**
   * Manual, from Bloqadmin.
   */
  Custom = 'Custom',
  Opp = 'Opp',
}

/**
 * The upper db object for an Investment.
 * An investments always contains a subcollection of Payments.
 */
export interface Investment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  asset: firebase.firestore.DocumentReference | Asset;
  paidEuroTotal?: number;
  openEuroTotal?: number;
  invoiceEuroTotal?: number;
  totalEuroRepayments?: number;
  boughtSharesTotal?: number;
  openSharesTotal?: number;
  managementFee?: number;
  pendingInvoiceCents?: number;
  paidPayments?: number;
  openPayments?: number;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  deleted: boolean;
  hasOpenPayments: boolean;
}

/**
 * Actual payment info is placed here.
 */
export interface Payment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  asset: firebase.firestore.DocumentReference | Asset;
  dividendsFormat: [string, number];
  provider: PaymentProvider;
  providerData: {
    id?: string,
    amount: {
      currency: string,
      value: string | number,
      totalAmount: number,
      feeAmount: number,
    },
    status: PaymentStatus,
    metadata: {
      uid?: string,
      euroAmount: number,
      sharesAmount: number,
      investmentId?: string,
      assetId?: string,
      paymentId?: string,
      selectedDividendsFormatYear?: [string, number];
    },
    [key: string]: any,
  };
  type?: string;
  deleted: boolean;
  ended?: firebase.firestore.Timestamp;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  paymentDateTime?: firebase.firestore.Timestamp;
  requestedDateTime?: firebase.firestore.Timestamp;
  refundedDateTime?: firebase.firestore.Timestamp;
  endDateTime?: firebase.firestore.Timestamp;
}

// Payment converter with key fields
export class SimplePaymentConverter {
  constructor(
    readonly date: firebase.firestore.Timestamp,
    readonly sharesAmount: number,
    readonly euroAmount: number,
  ) {
    this.date = date;
    this.sharesAmount = sharesAmount;
    this.euroAmount = euroAmount;
  }
}

export const simplePaymentConverter = {
  toFirestore(payment): firebase.firestore.DocumentData {
    return {
      date: payment.date,
      sharesAmount: payment.sharesAmount,
      euroAmount: payment.euroAmount,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): SimplePaymentConverter {
    const data = snapshot.data(options)! as Payment;
    return new SimplePaymentConverter(
      data.paymentDateTime || data.createdDateTime,
      data.providerData.metadata.sharesAmount,
      data.providerData.metadata.euroAmount,
    );
  },
};
