import { Module } from 'vuex';
import { State } from '@/models/State';
import asset from './asset';
import user from './user';
import identificationRequest from './identificationRequest';
import payment from './payment';
import repayment from './repayment';
import interestRate from './interestRate';
import interestRateType from './interestRateType';
import endDate from './endDate';
import deposit from './deposit';
import pescheck from './pescheck';
import { Vertebra } from '../utils/skeleton';

export default <{ [key: string]: Module<Vertebra, State> }>{
  asset,
  user,
  identificationRequest,
  payment,
  repayment,
  endDate,
  interestRate,
  interestRateType,
  deposit,
  pescheck,
};
