import { Module } from 'vuex';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyFunctions, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { Investment } from '@/models/investments/Investment';
import BigNumber from 'bignumber.js';
import to from 'await-to-js';
import { AssetRepayment, InvestmentRepayment } from '@/models/assets/Repayments';
import { Asset } from '@/models/assets/Asset';
import { AssetAccounts } from '@/models/assets/Deposit';
import { InvoicePaymentStatus } from '@/models/assets/Invoices';
import { CustomBatch } from '../utils/customBatch';
import { Vertebra, generateState, mutateState } from '../utils/skeleton';
import { periodCheck } from '../utils/checks';

export interface RepaymentActionParam {
  assetId: string,
  repaymentId: string,
  profile?: AssetAccounts,
}

export interface AddRepaymentParam {
  amount: number;
  investmentAmounts: { id: string, amount: number }[];
  assetId: string;
  date: Date;
  release: boolean;
  early: boolean;
  proForma: boolean;
  fees?: { description: string, amount: number }[];
}

const SET_REPAYMENTS = 'SET_REPAYMENTS';

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_REPAYMENTS](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addRepayment(
      { commit },
      { assetId, amount, investmentAmounts, date, release, early, proForma, fees }: AddRepaymentParam,
    ): Promise<void> {
      commit(SET_REPAYMENTS, { status: DataContainerStatus.Processing, operation: 'addRepayment' });

      let repaymentId = '';

      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = new CustomBatch(transaction);
        const dateNow = firebase.firestore.Timestamp.now();
        // Get asset
        const [getAssetError, getAssetSuccess] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAssetSuccess?.exists) {
          throw Error('Error retrieving the asset.');
        }
        const asset = getAssetSuccess.data() as Asset;

        // Check creation period
        const [checkError, checkResult] = await to(periodCheck(assetId, date.valueOf()));
        if (checkError) {
          throw checkError;
        }
        if (!checkResult) {
          throw Error('Can`t change asset financials for already generated periods');
        }

        if (!asset.activated) {
          throw Error('The project must be activated in order to be able to create repayments');
        }
        const repaymentDate = firebase.firestore.Timestamp.fromDate(date);

        if (early) {
          if (!asset.expectedRepaymentDate) {
            throw Error('The project doesn\'t have a minimun date set to perform an early repayment');
          }
          if (asset.expectedRepaymentDate < repaymentDate) {
            throw Error('This is not an early repayment');
          }
        }

        const currentValue = new BigNumber(asset.currentValueEuro || 0).minus(amount).toNumber();
        const fullyRepaid = currentValue <= 0 && !release;

        const amountSum = investmentAmounts.reduce((acum, now): BigNumber => acum.plus(now.amount), new BigNumber(0));
        if (amountSum.toNumber() !== amount) {
          throw Error('Sum of individuals amounts doesn`t match with the total amount. Please select individual amounts to ensure the rounding');
        }

        // Prepare references
        const assetRef = getAssetSuccess!.ref;
        const assetRepaymentRef = assetRef.collection('repayments').doc();
        repaymentId = assetRepaymentRef.id;

        // Create asset repayment
        const assetRepaymentUpdate: AssetRepayment = {
          totalAmount: amount,
          status: proForma ? InvoicePaymentStatus.Pending : InvoicePaymentStatus.Paid,
          asset: assetRef,
          repaymentDateTime: repaymentDate,
          createdDateTime: dateNow,
          release,
          early,
          fullyRepaid,
          ...(fees && { fees }),
          deleted: false,
        };
        transactionBatch.addOperation({
          ref: assetRepaymentRef,
          data: assetRepaymentUpdate,
        });

        if (!proForma) {
          // Update asset
          transactionBatch.addOperation({
            ref: assetRef,
            data: {
              currentValueEuro: currentValue,
              ...release && { sharesAvailable: new BigNumber(asset.sharesAvailable).plus(new BigNumber(amount).multipliedBy(100)).toNumber() },
              ...(fullyRepaid && {
                finished: true,
                lastRepaymentDate: repaymentDate,
              }),
              updatedDateTime: dateNow,
            } as Asset,
          });
        }

        // for each investment in asset
        const [investmentRepaymentsError] = await to(Promise.all(
          investmentAmounts.map(async (investmentAmount): Promise<void> => {
            const investmentRef = bloqifyFirestore.collection('investments').doc(investmentAmount.id);
            const [getInvestmentError, getInvestmentSuccess] = await to(transaction.get(investmentRef));
            if (getInvestmentError || !getInvestmentSuccess?.exists) {
              throw Error('Error retrieving the investor.');
            }
            const investment = getInvestmentSuccess.data() as Investment;

            // Set investment repayment
            const investmentRepaymentRef = investmentRef.collection('repayments').doc();
            const investmentRepaymentData: InvestmentRepayment = {
              deleted: false,
              status: proForma ? InvoicePaymentStatus.Pending : InvoicePaymentStatus.Paid,
              amount: investmentAmount.amount || 0,
              investment: investmentRef,
              investor: investment.investor,
              createdDateTime: dateNow,
              repaymentDateTime: repaymentDate,
              assetRepayment: assetRepaymentRef,
              release,
              early,
              fullyRepaid,
            };
            transactionBatch.addOperation({
              ref: investmentRepaymentRef,
              data: investmentRepaymentData,
            });

            if (!proForma) {
              // Update investment totals
              transactionBatch.addOperation({
                ref: investmentRef,
                data: {
                  totalEuroRepayments: new BigNumber(investment.totalEuroRepayments || 0).plus(investmentAmount.amount).toNumber(),
                  ...release && {
                    boughtSharesTotal: new BigNumber(investment.boughtSharesTotal || 0).minus(new BigNumber(investmentAmount.amount).multipliedBy(100)).toNumber(),
                  },
                  updatedDateTime: dateNow,
                } as Investment,
              });
            }
          }),
        ));
        if (investmentRepaymentsError) {
          throw investmentRepaymentsError;
        }

        // Commit
        transactionBatch.commit();
      }));
      if (transactionError) {
        return commit(SET_REPAYMENTS, { status: DataContainerStatus.Error, payload: transactionError, operation: 'addRepayment' });
      }

      const [generateRepaymentInvoiceError] = await to<any>(bloqifyFunctions.httpsCallable('generateRepaymentInvoice')({ assetId, repaymentId }));
      if (generateRepaymentInvoiceError) {
        throw Error('Error generating repayment note.');
      }

      return commit(SET_REPAYMENTS, { status: DataContainerStatus.Success, operation: 'addRepayment' });
    },
    async deleteRepayment(
      { commit },
      { assetId, repaymentId }: RepaymentActionParam,
    ): Promise<void> {
      commit(SET_REPAYMENTS, { status: DataContainerStatus.Processing, operation: 'deleteRepayment' });

      // Call BQ function to innitiate process, checks will be performed there
      const [generateInvoicesError] = await to(bloqifyFunctions.httpsCallable('financialsOperations')({
        assetId,
        docId: repaymentId,
        object: 'repayment',
        action: 'delete',
      }));
      if (generateInvoicesError) {
        return commit(SET_REPAYMENTS, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'deleteRepayment' });
      }

      return commit(SET_REPAYMENTS, { status: DataContainerStatus.Success, operation: 'deleteRepayment' });
    },
    async markRepaymentAsPaid(
      { commit },
      { assetId, repaymentId }: RepaymentActionParam,
    ): Promise<void> {
      commit(SET_REPAYMENTS, { status: DataContainerStatus.Processing, operation: 'markRepaymentAsPaid' });

      // Call BQ function to innitiate process, checks will be performed there
      const [generateInvoicesError] = await to(bloqifyFunctions.httpsCallable('financialsOperations')({
        assetId,
        docId: repaymentId,
        object: 'repayment',
        action: 'markAsPaid',
      }));
      if (generateInvoicesError) {
        return commit(SET_REPAYMENTS, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'markRepaymentAsPaid' });
      }

      return commit(SET_REPAYMENTS, { status: DataContainerStatus.Success, operation: 'markRepaymentAsPaid' });
    },
    async sendRepayment(
      { commit },
      { assetId, repaymentId }: RepaymentActionParam,
    ): Promise<void> {
      commit(SET_REPAYMENTS, { status: DataContainerStatus.Processing, operation: 'sendRepayment' });

      const [generateInvoicesError] = await to(bloqifyFunctions.httpsCallable('sendRepayment')({ assetId, repaymentId }));
      if (generateInvoicesError) {
        return commit(SET_REPAYMENTS, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'sendRepayment' });
      }

      return commit(SET_REPAYMENTS, { status: DataContainerStatus.Success, operation: 'sendRepayment' });
    },
    async payFromDepositRepayment(
      { commit },
      { assetId, repaymentId, profile }: RepaymentActionParam,
    ): Promise<void> {
      commit(SET_REPAYMENTS, { status: DataContainerStatus.Processing, operation: 'payFromDepositRepayment' });

      // Call BQ function to innitiate process, checks will be performed there
      const [generateInvoicesError] = await to(bloqifyFunctions.httpsCallable('financialsOperations')({
        assetId,
        docId: repaymentId,
        object: 'repayment',
        action: 'payFromDeposit',
        profile,
      }));
      if (generateInvoicesError) {
        return commit(SET_REPAYMENTS, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'payFromDepositRepayment' });
      }

      return commit(SET_REPAYMENTS, { status: DataContainerStatus.Success, operation: 'payFromDepositRepayment' });
    },
  },
};
