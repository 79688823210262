export function currency(value: number, locale: string = 'nl-NL', decimals: number = 2): string {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(value || 0);
}

export default {
  currency,
};
