import { ActionContext, Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyFunctions, bloqifyStorage, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { Investor, isInvestor, User, UserStatus, UserTier } from '@/models/users/User';
import { OppStatus, OppStatusSimplified } from '@/models/opp/Opp';
import { UserData } from '@/models/identification-requests/pescheck';
import {
  IdentificationRequest,
  IdentificationRequestStatus,
} from '@/models/identification-requests/IdentificationRequest';
import { Idin } from '@/models/identification-requests/idin';
import { QuestionnaireAnswer, QuestionnaireAnswers } from '@/models/users/Questionnaire';
import moment from 'moment';
import { generateState, mutateState, Vertebra } from '../utils/skeleton';
import { generateFileMd5Hash } from '../utils/files';

const SET_USER = 'SET_USER';

/*
* unfortunately it is not possible to extend enums directly
* thus we create an object combining the enums
* but an object is not a type too in TS thus the type is declared explicitly
* on import both are imported automatically
*/
enum StatusRest {
  Error = 'error',
  None = 'none',
}

export interface GetMerchantStatusOutput {
  merchantStatus: string;
  complianceStatus: string;
  complianceUrl: string;
  requirements: string[];
}

export type SendQuestionnaireParam = { questionnaireAnswers: QuestionnaireAnswers, userId: string };

export default <Module<Vertebra, State>>{
  state: generateState(),
  mutations: {
    [SET_USER](
      state,
      { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async handleUserStatus(
      { commit }: ActionContext<Vertebra, State>,
      { id, status, statusMessage }: { id: string, status: User['status'], statusMessage?: User['statusMessage'] },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'handleUserStatus' });

      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
      const [transactionUpdateError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const documentRef = bloqifyFirestore.collection('investors').doc(id);
        const [readUser, readUserSuccess] = await to(transaction.get(documentRef));
        if (readUser || !readUserSuccess?.exists) {
          throw readUser || Error('Error getting the payment.');
        }

        transaction.update(
          documentRef,
          {
            status,
            ...statusMessage && { statusMessage },
            updatedDateTime: serverTimestamp,
          },
        );

        const investorCounter = [UserTier.Investor, UserTier.LoanTaker, UserTier.Notary].includes((readUserSuccess.data() as User).tier) ? 1 : 0;
        transaction.update(
          bloqifyFirestore.collection('settings').doc('counts'),
          {
            activeUsers: firebase.firestore.FieldValue.increment(status === UserStatus.Disabled ? -1 : 1),
            activeInvestors: firebase.firestore.FieldValue.increment(status === UserStatus.Disabled ? -investorCounter : investorCounter),
            updatedDateTime: serverTimestamp,
          },
        );
      }));

      if (transactionUpdateError) {
        commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: transactionUpdateError,
          operation: 'handleUserStatus',
        });
        return;
      }

      commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: { status },
        operation: 'handleUserStatus',
      });
    },
    async deleteUser(
      { commit }: ActionContext<Vertebra, State>,
      { id, statusMessage }: { id: string, statusMessage?: User['statusMessage'] },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'deleteUser' });

      const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
      const [transactionUpdateError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const investorRef = bloqifyFirestore.collection('investors').doc(id);
        const [getUserError, getUserSuccess] = await to(transaction.get(investorRef));
        if (getUserError || !getUserSuccess?.exists) {
          throw getUserError || Error('Error getting the user.');
        }

        transaction.update(
          investorRef,
          {
            deleted: true,
            status: UserStatus.Disabled,
            ...statusMessage && { statusMessage },
            updatedDateTime: serverTimestamp,
          },
        );

        const investorCounter = [UserTier.Investor, UserTier.LoanTaker, UserTier.Notary].includes((getUserSuccess.data() as User).tier) ? 1 : 0;
        transaction.update(
          bloqifyFirestore.collection('settings').doc('counts'),
          {
            activeUsers: firebase.firestore.FieldValue.increment(-1),
            activeInvestors: firebase.firestore.FieldValue.increment(-investorCounter),
            updatedDateTime: serverTimestamp,
          },
        );
      }));
      if (transactionUpdateError) {
        commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: transactionUpdateError,
          operation: 'deleteUser',
        });
        return;
      }

      commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: { status },
        operation: 'deleteUser',
      });
    },
    async sendQuestionnaire(
      { commit, state }: ActionContext<Vertebra, State>,
      data: SendQuestionnaireParam,
    ): Promise<void> {
      const storageUploads: firebase.storage.UploadTask[] = []; // collect the upload tasks in this array

      // todo don't upload files if already exists
      // store data if needed and transform to link
      const answersToStore: QuestionnaireAnswer[] = data.questionnaireAnswers.answers.map((answer, index): QuestionnaireAnswer => {
        if (answer.type === 'file') {
          const file = answer.answer as unknown as File;
          const fileName = `investors/${data.userId}/questionnaire${index}.${file.name}`;
          storageUploads.push(bloqifyStorage.ref().child(fileName).put(file));

          return {
            answer: fileName,
            type: 'file',
          };
        }
        return answer;
      });

      const [uploadAnswersError] = await to(Promise.all(storageUploads)); // upload files
      if (uploadAnswersError) {
        throw Error(uploadAnswersError.message);
      }

      const objToStore: QuestionnaireAnswers = {
        answers: answersToStore,
        createdDateTime: data.questionnaireAnswers.createdDateTime,
        questions: data.questionnaireAnswers.questions,
      };
      const [dbError] = await to( // write answers to firestore
        bloqifyFirestore
          .collection('investors')
          .doc(data.userId)
          .update({
            questionnaire: objToStore,
          }),
      );
      if (dbError) {
        throw Error(dbError.message);
      }
    },
    async createInvestorWalletId(
      { commit }: ActionContext<Vertebra, State>,
      { investorId }: { investorId: string },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'createInvestorWalletId' });

      const [createInvestorWalletError, createInvestorWallet] = await to(bloqifyFunctions.httpsCallable('createInvestorWalletId')({ investorId }));
      if (createInvestorWalletError) {
        return commit(SET_USER, { status: DataContainerStatus.Error, payload: createInvestorWalletError, operation: 'createInvestorWalletId' });
      }

      return commit(SET_USER, { status: DataContainerStatus.Success, payload: createInvestorWallet!.data, operation: 'createInvestorWalletId' });
    },
    async updateInvestorOppBankAccount(
      { commit }: ActionContext<Vertebra, State>,
      { merchantId }: { merchantId: string },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'updateInvestorOppBankAccount' });

      const [createNewBankAccountError, createNewBankAccountSuccess] = await to(bloqifyFunctions.httpsCallable('createNewBankAccount')({ merchantId }));
      if (createNewBankAccountError) {
        return commit(SET_USER, { status: DataContainerStatus.Error, payload: createNewBankAccountError, operation: 'updateInvestorOppBankAccount' });
      }

      return commit(SET_USER, { status: DataContainerStatus.Success, payload: createNewBankAccountSuccess!.data, operation: 'updateInvestorOppBankAccount' });
    },
    async approveMerchant(
      { commit }: ActionContext<Vertebra, State>,
      { investorId }: { investorId: string },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'approveMerchant' });

      const [createInvestorWalletError, createInvestorWallet] = await to(bloqifyFunctions.httpsCallable('approveInvestorWallet')({ investorId }));
      if (createInvestorWalletError) {
        return commit(SET_USER, { status: DataContainerStatus.Error, payload: createInvestorWalletError, operation: 'approveMerchant' });
      }

      return commit(SET_USER, { status: DataContainerStatus.Success, payload: createInvestorWallet!.data, operation: 'approveMerchant' });
    },
    async fetchOppStatus(
      { commit }: ActionContext<Vertebra, State>,
      merchant: string,
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'fetchOppStatus' });

      const [getOppStatusError, getOppStatus] = await to(bloqifyFunctions.httpsCallable('getMerchantStatus')({ merchant }));
      if (getOppStatusError) {
        return commit(SET_USER, { status: DataContainerStatus.Error, payload: getOppStatusError, operation: 'fetchOppStatus' });
      }

      return commit(SET_USER, { status: DataContainerStatus.Success, payload: getOppStatus!.data as OppStatus, operation: 'fetchOppStatus' });
    },
    async createUser(
      { commit }: ActionContext<Vertebra, State>,
      user: (User | Investor) & { password: string },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'createUser' });

      const { email, password, ...restOfInvestor } = user;

      const [createFirebaseUser, createUserSuccess] = await to(bloqifyFunctions.httpsCallable('createUser')({
        email,
        password,
      }));
      if (createFirebaseUser) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: createFirebaseUser,
          operation: 'createUser',
        });
      }

      const id = createUserSuccess?.data.uid;

      if (!id) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: new Error('There was an error retrieving the user id.'),
          operation: 'createUser',
        });
      }

      const storageRef = bloqifyStorage.ref();
      const getExtension = (type: string): string => type.substring(type.lastIndexOf('/') + 1, type.length);

      const fileHandler = async (filePath: string): Promise<any> => {
        const file = restOfInvestor[filePath] as File;
        const files = Array.isArray(file) ? file : [file];

        if (files.some((file): any => file)) {
          const [uploadFilesError, uploadFilesSuccess] = await to(Promise.all(files.map(async (file, index: number): Promise<any> => {
            if (!file) { return null; }

            const md5Hash = await generateFileMd5Hash(file, true);
            const path = `investors/${id}/${file.name.replace(/(\.\w+)+$/, '')}.${getExtension(file.type)}`;

            const fileRef = storageRef.child(path);
            await fileRef.put(file, { customMetadata: { md5Hash } });

            return path;
          })));
          if (uploadFilesError) {
            throw uploadFilesError;
          }
          restOfInvestor[filePath] = Array.isArray(file) ? uploadFilesSuccess : uploadFilesSuccess![0];
        }

        return (): void => undefined;
      };

      const [uploadFilesError] = await to(Promise.all([
        fileHandler('passport'),
        fileHandler('kvkImage'),
      ]));

      if (uploadFilesError) {
        let errMessage = 'User update failed.';

        if ((uploadFilesError as any).code === 'storage/unauthorized') {
          console.error(`${uploadFilesError.message} => Likely this file already exists`);
          errMessage = 'User update failed: Likely this file already exists.';
        }

        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: new Error(errMessage),
          operation: 'createUser',
        });
      }

      if (createUserSuccess) {
        const [createUserError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
          if (this.state.settings!.pescheck) {
            try {
              const userData: UserData = {
                email,
                first_name: (user as Investor).name,
                last_name: (user as Investor).surname,
                watchlist_threshold: '70',
                watchlist_date_of_birth: moment((user as Investor).dateOfBirth.toDate()).format('YYYY-MM-DD'),
              };
              const [requestPescheckError, requestPescheckSuccess] = await to(bloqifyFunctions.httpsCallable('requestPescheck')({
                userData,
              }));

              if (requestPescheckError) {
                throw requestPescheckError || Error('Error requesting Pescheck.');
              }

              const pescheckRef = bloqifyFirestore.collection('pescheckv3_data').doc(id);

              transaction.set(pescheckRef, {
                initialRequest: requestPescheckSuccess?.data.screeningData,
                createdDateTime: firebase.firestore.FieldValue.serverTimestamp(),
                updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
              });
              restOfInvestor.pescheck = pescheckRef;
            } catch (e) {
              console.error(`Failed while performing the pescheck: ${e}`);
            }
          }

          transaction.set(bloqifyFirestore.collection('investors').doc(id), {
            ...restOfInvestor,
            email,
            status: UserStatus.Enabled,
            idRequestStatus: IdentificationRequestStatus.Approved,
            oppStatus: OppStatusSimplified.NONE,
            createdDateTime: firebase.firestore.FieldValue.serverTimestamp(),
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          } as User, { merge: true });
          transaction.update(bloqifyFirestore.collection('settings').doc('counts'), {
            activeInvestors: firebase.firestore.FieldValue.increment(1),
            // no need to update activeUser since that is done in the CF
          });
        }));
        if (createUserError) {
          return commit(SET_USER, {
            status: DataContainerStatus.Error,
            payload: createUserError,
            operation: 'createUser',
          });
        }
      }

      return commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: id,
        operation: 'createUser',
      });
    },
    /**
     * Update function that handles also the firebase auth email change.
     */
    async updateUser(
      { commit }: ActionContext<Vertebra, State>,
      user: (User | Investor) & { uid: string }, uploadFiles: boolean = true,
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'updateUser' });
      const { uid, ...restOfInvestor } = user;
      const userRef = bloqifyFirestore.collection('investors').doc(uid);
      const storageRef = bloqifyStorage.ref();
      const getExtension = (type: string): string => type.substring(type.lastIndexOf('/') + 1, type.length);

      const fileHandler = async (filePath: string): Promise<any> => {
        const file = restOfInvestor[filePath] as File;
        const files = Array.isArray(file) ? file : [file];

        if (files.some((file): any => file)) {
          const [uploadFilesError, uploadFilesSuccess] = await to(Promise.all(files.map(async (file, index: number): Promise<any> => {
            if (!file || typeof file === 'string') { return null; }

            const [md5HashError, md5HashSuccess] = await to(generateFileMd5Hash(file, true));
            if (md5HashError) {
              throw md5HashError;
            }
            const md5Hash = md5HashSuccess!;
            const path = `investors/${uid}/${file.name.replace(/(\.\w+)+$/, '')}.${getExtension(file.type)}`;

            const fileRef = storageRef.child(path);
            await fileRef.put(file, { customMetadata: { md5Hash } });

            return path;
          })));
          if (uploadFilesError) {
            throw uploadFilesError;
          }
          restOfInvestor[filePath] = Array.isArray(file) ? uploadFilesSuccess : uploadFilesSuccess![0];
        }

        return (): void => undefined;
      };

      if (uploadFiles) {
        const [uploadFilesError] = await to(Promise.all([
          fileHandler('passport'),
          fileHandler('kvkImage'),
          fileHandler('navStatements'),
        ]));
        if (uploadFilesError) {
          let errMessage = 'User update failed.';

          if ((uploadFilesError as any).code === 'storage/unauthorized') {
            console.error(`${uploadFilesError.message} => Likely this file already exists`);
            errMessage = 'User update failed: Likely this file already exists.';
          }

          return commit(SET_USER, {
            status: DataContainerStatus.Error,
            payload: new Error(errMessage),
            operation: 'updateUser',
          });
        }
      }

      // The call to the Bloqify cloud function (Admin SDK) is only neccessary if the email changed
      const [getUserError, userSnapshot] = await to(userRef.get());
      if (getUserError || !userSnapshot) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: new Error('There was an error retrieving the user.'),
          operation: 'updateUser',
        });
      }
      if (user.email && userSnapshot.get('email') !== user.email) {
        const [updateUserError] = await to(bloqifyFunctions.httpsCallable('updateUserEmail')({
          uid,
          email: user.email,
        }));

        if (updateUserError) {
          return commit(SET_USER, {
            status: DataContainerStatus.Error,
            payload: updateUserError,
            operation: 'updateUser',
          });
        }
      }

      const [updateUserError] = await to(userRef.update(
        {
          ...restOfInvestor,
          idRequestStatus: IdentificationRequestStatus.Approved,
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        },
      ));
      if (updateUserError) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: updateUserError,
          operation: 'updateUser',
        });
      }

      return commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: user,
        operation: 'updateUser',
      });
    },
    async updateUserNotes(
      { commit }: ActionContext<Vertebra, State>,
      user: (User | Investor) & { uid: string },
    ): Promise<void> {
      commit(SET_USER, { status: DataContainerStatus.Processing, operation: 'updateUserNotes' });
      const userRef = bloqifyFirestore.collection('investors').doc(user.uid);
      const [updateUserError] = await to(userRef.update({
        quickNotes: user.quickNotes,
        updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
      }));

      if (updateUserError) {
        return commit(SET_USER, {
          status: DataContainerStatus.Error,
          payload: updateUserError,
          operation: 'updateUserNotes',
        });
      }

      return commit(SET_USER, {
        status: DataContainerStatus.Success,
        payload: user,
        operation: 'updateUserNotes',
      });
    },
  },
};
