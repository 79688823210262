import { firebase } from '@/boot/firebase';

export interface Operation {
  ref: firebase.firestore.DocumentReference;
  data: { [key: string]: any };
}

export class CustomBatch {
  transaction: firebase.firestore.Transaction;
  operations: Operation[];
  constructor(transaction: firebase.firestore.Transaction) {
    this.transaction = transaction;
    this.operations = [];
  }

  addOperation(oper: Operation): void {
    this.operations.push(oper);
  }

  commit(): void {
    this.operations.forEach((row): void => {
      this.transaction.set(row.ref, row.data, { merge: true });
    });
  }
}
