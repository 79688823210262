import firebase from 'firebase/app';
import { Investor } from './users/User';
import { AssetPaymentMethod, PaymentMethod } from './opp/Opp';
import { DurationMethods, FundingTypes, InterestRateTypes } from './assets/Asset';
import { SetupFee } from './assets/Fees';
import { PaymentStatus } from './investments/Investment';
import { InvoicePaymentStatus } from './assets/Invoices';

export const enum DataContainerStatus {
  Initial = 'initial',
  Processing = 'processing',
  Success = 'success',
  Error = 'error',
}
export interface DataContainerInterface<T = any> {
  status: DataContainerStatus;
  payload: T | null;
  error?: string | Error | firebase.auth.Error;
  name?: string;
  length?: number | null;

  loading(): boolean;
}

export class DataContainer<T> implements DataContainerInterface<T> {
  status: DataContainerStatus = DataContainerStatus.Initial;
  payload = null;
  error = '';
  name = '';
  length = null;

  /**
   * Returns whether data is loading.
   */
  public loading(): boolean {
    return this.status === DataContainerStatus.Initial || this.status === DataContainerStatus.Processing;
  }
}

export interface VuexfireSnapshot {
  id: string;
}

export interface FileHandler {
  name: string,
  fullPath: string,
  file: File,
}

// Client Docs
export interface ClientDocs {
  logo: string[] | FileHandler[],
  registration: string[] | FileHandler[],
}
export type DocsFields = keyof ClientDocs;

// Client Data
export interface ClientData {
  name: string,
  companyName?: string,
  supportName?: string,
  email?: string,
  url?: string,
  dir?: string,
  telephone?: string,
  kvk?: string,
  iban?: string,
  bic?: string,
  btw?: string,
}

export enum AlignmentOptions {
  left = 'left',
  center = 'center',
  right = 'right',
}

export enum SizeOptions {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export enum StyleOptions {
  Regular = 'Regular',
  Italic = 'Italic',
  Bold = 'Bold',
  BoldItalic= 'BoldItalic',
}

export enum FontOptions {
  Brother1816 = 'Brother1816',
  DMsans = 'DMsans',
  Arial = 'Arial',
}

// Doc Format
export interface DocFormat {
  logoAlignment: AlignmentOptions,
  logoSize: SizeOptions,
  titleAlignment: AlignmentOptions,
  titleSize: SizeOptions,
  titleStyle: StyleOptions,
  fontSize: SizeOptions,
  font: FontOptions,
}

// Config for the reminders
export interface Reminder {
  enabled: boolean | string,
  template: string,
  to: 'loanTakers' | 'support' | 'investor',
  daysDiff: number,
}

// Config for the asset reminders
export interface AssetReminder extends Reminder {
  field: 'startDateTime' | 'endDateTime',
  to: 'loanTakers' | 'support',
  // TODO with auto invest
  status: 'published' |'activated' | 'finished',
}

// Config for the invoice reminders
export interface InvoiceReminder extends Reminder {
  field: 'sendDateTime' | 'period.start' | 'period.end',
  to: 'loanTakers' | 'support' | 'investor',
  status: InvoicePaymentStatus,
}

// Config for the payment reminders
export interface PaymentReminder extends Reminder {
  field: 'createdDateTime' | 'paymentDateTime' | 'requestedDateTime' | 'refundedDateTime',
  to: 'investor' | 'support',
  status: PaymentStatus,
}

export interface Templates {
  acceptOperation: string,
  passwordReset: string,
  verifyEmail: string,
  sendMandateActivation: string,
  mandateRefunded: string,
  mandateRevoked: string,
  identificationRequestSuccess: string,
  identificationRequestFailed: string,
  sharesBought: string,
  sharesReserved: string,
  sendInvoice: string,
  sendInvoiceDirectLink: string,
  sendInvoiceMandate: string,
  sendInvoiceDeposit: string,
  sendManualInvoiceDirectLink: string,
  sendRepaymentDirectLink: string,
  investorInvoice: string,
  notifyPublishedAsset: string,
  invoicePaidNotification: string,
  requestPayment: string,
  assetReminders: AssetReminder[],
  invoiceReminders: InvoiceReminder[],
  paymentReminders: PaymentReminder[],
}

export enum ObjectContentOptions {
  Address = 'address',
  ProjectName = 'projectName',
}

export interface InvoiceFormat {
  showInvestors: boolean, // Show list of investors in the invoice
  showAllocation: boolean, // Show payment split with investors names, amounts and bankaccounts
  showLoanTakerInfo: boolean, // Show loantaker address in Invoice
  onlyUseFirstLoantaker: boolean, // Only send the name of the first loantaker
  showPeriodNumber: boolean, // Displays period number instead of period dates
  paymentDayInvoices: number, // Day of the month to pay the invoices
  paymentMonthInvoices: number, // Month to pay the invoices (0 - current month, 1 - next month)
  objectContent: ObjectContentOptions, // Content to display in the invoices in the object fields
  invoiceFileName: string, // Name of the file used to store the rente notas
  footerText: { [key in AssetPaymentMethod]: string }, // Additional text to the footer of the invoice nota
}

export interface InvoiceFormula {
  dateFormat: number, // 0 - Precise / 1 - 30-360
  paymentsFormat: number, // 0 - Count the day changed / 1 - Start counting next day
  interestFormat: number, // 0 - Count the day changed / 1 - Start counting next day
  repaymentsFormat: number, // 0 - Count the day changed / 1 - Start counting next day
  // for annuitys and linear loans
  complexPaymentsFormat: number, // 0 - Count from the start of the month / 1 - Start counting next month
  complexInterestFormat: number, // 0 - Count from the start of the month / 1 - Start counting next month
  complexRepaymentsFormat: number, // 0 - Count from the start of the month / 1 - Start counting next month
}

export enum AssetVisibility {
  Invested = 'invested',
  NotFull = 'not-full',
}

export enum ClientCountry {
  NL = 'NL',
  BE = 'BE',
  ES = 'ES',
  US = 'US',
  UK = 'UK',
}

export enum UserSelectors {
  PrivateName = 'PrivateName',
  CompanyName = 'CompanyName',
  Both = 'Both',
}

export interface TextLangOptions {
  en: string;
  nl: string;
}

export interface InvestorPortalConfig {
  showAssets: boolean;
  assetVisibility: AssetVisibility[];
  showWithdrawDisclaimer: boolean;
  showDownloadInvoice: boolean;
  showInterestRate: boolean;
  showProgressBar: boolean;
  showPersonalDoc: boolean;
  requireQuestionnaire: boolean;
  requireContractAgreement: boolean;
  requirePrivateApproval: boolean;
  requireBusinessApproval: boolean;
  showPhoneNumberInput: boolean;
  clientCountry: ClientCountry;
  requireBankAccount: boolean;
  requireIdCopy: boolean;
  requireTelephone: boolean;
  formalTranslations: boolean;
  showInvoiceManagementFee: boolean;
  showInvoiceManagementFeeVat: boolean;
  showManagementFee: boolean;
  investmentConditions: TextLangOptions,
  registrationConditions: TextLangOptions,
  questionnaireValidity: number, // determines the validity of a questionnaire of an investor and when it needs to be filled out again
  simulationTestValidity: number, // determines the validity of a simulation test of an investor and when it needs to taken again
  decimals: number,
}

export interface AdminPortalConfig {
  showExternalId: boolean;
  userSelectors?: UserSelectors;
}

export enum WalletSettlement {
  daily = 'daily',
  weekly = 'weekly',
  'bi-weekly' = 'bi-weekly',
  monthly = 'monthly',
  yearly = 'yearly',
  continuous = 'continuous',
}

export interface Settings {
  maintenance: boolean;
  bloqadminMaintenance: boolean;
  slowPayerProtection: boolean;
  restoring: boolean;
  generateInvoices: number;
  investorPortalEnabled: boolean;
  sendInvoices: number;
  oppIntegration: boolean;
  pescheck: boolean;
  userEmailing: boolean;
  businessEmailing: boolean;
  availableLanguages: string[];
  clientAccount?: firebase.firestore.DocumentReference | Investor; // Client app account
  defaultPaymentMethod: AssetPaymentMethod;
  defaultInterestRateType: InterestRateTypes;
  durationMethod: DurationMethods;
  clientData: ClientData;
  clientDocs: ClientDocs;
  docFormat: DocFormat;
  templates: Templates;
  invoiceFormat: InvoiceFormat; // Changes in the invoice doc generated
  invoiceFormula: InvoiceFormula; // Settings available to apply in the invoice generation formula
  availablePaymentMethods: AssetPaymentMethod[]; // Available payment methods for the invoices
  paymentOptions: PaymentMethod[]; // Allowed payment options
  defaultProjectFunding: FundingTypes; // Default project funding
  support_email: string; // Support Email, previously on the firebase functions config
  base_url: string; // Platform url, previously on the firebase functions config
  investorPortalConfig: InvestorPortalConfig; // Different setups for the bloqify page
  adminPortalConfig: AdminPortalConfig; // More setups for the bloqadmin page
  sendInvoicePaidNotification: boolean; // Send an email to investors when an invoice is paid
  countryVat: number; // Vat of the country where the platform mainly operate
  managementFeeFormula: SetupFee; // Formula to apply in the management fee
  administrationFeeFormula: SetupFee; // Formula to apply in the administration fee
  defaultAssetSetupFees: SetupFee[]; // Default fees when creating a new asset
  requireBankAccountMatch: boolean; // Enforce that investors bankaccount is the same in our bd and in Opp
  walletSettlement: WalletSettlement; // Investors wallet settlement config
  allowWalletPayment: boolean; // Allows investing with investor wallet funds
  decimalPrecision: boolean; // Controls invoice decimal precission
  overidePayoutProtection: boolean; // Allow to perform payouts without having to wait 4 days
}

export interface Counts {
  activeUsers: number,
  activeInvestors: number,
  openPayments: number,
  paidPayments: number,
  pendingIdentificationRequests: number,
  publishedAssets: number,
  totalInvested: number,
  currentInvested: number,
  interestPerMonth: number,
  currentYearFees: number,
  feesPerMonth: number,
  chartData: number[],
  updatedDateTime: firebase.firestore.Timestamp,
}

export interface Backup {
  location: {
    bucket: string;
    path: string;
  };
  service: object;
}
