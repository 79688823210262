import { Module } from 'vuex';
import { State } from '@/models/State';
import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { Asset } from '@/models/assets/Asset';
import { AssetInterestRate, InterestRateStatus } from '@/models/assets/InterestRates';
import { DataContainerStatus } from '@/models/Common';
import to from 'await-to-js';
import { CustomBatch } from '../utils/customBatch';
import { periodCheck } from '../utils/checks';
import { Vertebra, generateState, mutateState } from '../utils/skeleton';

export interface DeleteInterestRateParam {
  assetId: string,
  interestRateId: string,
}

export interface AddInterestRateParam {
  assetId: string;
  amount: number;
  date: Date;
}

const SET_INTERESTRATE = 'SET_INTERESTRATE';

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_INTERESTRATE](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addInterestRate(
      { commit },
      { assetId, amount, date }: AddInterestRateParam,
    ): Promise<void> {
      commit(SET_INTERESTRATE, { status: DataContainerStatus.Processing, operation: 'addInterestRate' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = new CustomBatch(transaction);
        const now = new Date();
        const futureDate = now < date;

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }
        const asset = getAsset.data() as Asset;

        // Only allow to use this module if the asset is activated
        if (!asset.activated) {
          throw Error('Until the asset is activated please use the edit asset page to change interest rates');
        }

        const [checkError, checkResult] = await to(periodCheck(assetId, date.valueOf()));
        if (checkError) {
          throw checkError;
        }
        if (!checkResult) {
          throw Error('Can`t change asset financials for already generated periods');
        }

        const interestRateRef = getAsset.ref.collection('interestRates').doc();
        const data = {
          asset: getAsset.ref,
          deleted: false,
          amount,
          status: futureDate ? InterestRateStatus.Pending : InterestRateStatus.Applied,
          appliedDateTime: firebase.firestore.Timestamp.fromDate(date),
          createdDateTime: firebase.firestore.Timestamp.fromDate(now),
          updatedDateTime: firebase.firestore.Timestamp.fromDate(now),
        } as AssetInterestRate;

        transactionBatch.addOperation({
          ref: interestRateRef,
          data,
        });

        const [getAllInterestRatesError, getAllInterestRates] = await to((getAsset.ref.collection('interestRates')
          .where('deleted', '==', false)
          .where('status', '==', InterestRateStatus.Applied)
          .orderBy('appliedDateTime', 'desc')
          .get()));

        if (getAllInterestRatesError) {
          throw Error(getAllInterestRatesError.message);
        }

        const recentDate = date.valueOf() >= (getAllInterestRates?.docs[0]?.get('appliedDateTime')?.toDate().valueOf() || -Infinity);

        if (!futureDate && recentDate) {
          transactionBatch.addOperation({
            ref: getAsset.ref,
            data: {
              returnsAfterEnd: amount,
              updatedDateTime: firebase.firestore.Timestamp.fromDate(now),
            } as Asset,
          });
        }

        transactionBatch.commit();
      }));
      if (transactionError) {
        return commit(SET_INTERESTRATE, { status: DataContainerStatus.Error, payload: transactionError, operation: 'addInterestRate' });
      }
      return commit(SET_INTERESTRATE, { status: DataContainerStatus.Success, operation: 'addInterestRate' });
    },
    async deleteInterestRate(
      { commit },
      { assetId, interestRateId }: DeleteInterestRateParam,
    ): Promise<void> {
      commit(SET_INTERESTRATE, { status: DataContainerStatus.Processing, operation: 'deleteInterestRate' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = new CustomBatch(transaction);
        const now = new Date();
        if (interestRateId === 'initial') {
          throw Error('Can\'t delete initial interest rate of the project');
        }

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }
        const asset = getAsset.data() as Asset;

        const [getInterestRateError, getInterestRate] = await to(transaction.get(getAsset.ref.collection('interestRates').doc(interestRateId)));
        if (getInterestRateError || !getInterestRate || !getInterestRate.exists) {
          throw Error(getInterestRateError?.message || 'Interest rate not found');
        }
        const interestRate = getInterestRate.data() as AssetInterestRate;

        if (interestRate.deleted) {
          throw Error('Interest rate already erased');
        }

        const [checkError, checkResult] = await to(periodCheck(assetId, interestRate.appliedDateTime.toMillis()));
        if (checkError) {
          throw checkError;
        }
        if (!checkResult) {
          throw Error('Can`t change asset financials for already generated periods');
        }

        transactionBatch.addOperation({
          ref: getInterestRate.ref,
          data: {
            deleted: true,
            updatedDateTime: firebase.firestore.Timestamp.fromDate(now),
          } as AssetInterestRate,
        });

        const [getAllInterestRatesError, getAllInterestRates] = await to(getAsset.ref.collection('interestRates')
          .where('deleted', '==', false)
          .where('status', '==', InterestRateStatus.Applied)
          .orderBy('appliedDateTime', 'desc')
          .get());

        if (getAllInterestRatesError) {
          throw Error(getAllInterestRatesError.message);
        }

        const lastInterestRate = getAllInterestRates?.docs[0].id === getInterestRate.id;
        const previousAmount = Number(getAllInterestRates?.docs[1]?.get('amount') || 0);

        if (lastInterestRate) {
          transactionBatch.addOperation({
            ref: getAsset.ref,
            data: {
              returnsAfterEnd: previousAmount,
              updatedDateTime: firebase.firestore.Timestamp.fromDate(now),
            } as Asset,
          });
        }

        transactionBatch.commit();
      }));
      if (transactionError) {
        return commit(SET_INTERESTRATE, { status: DataContainerStatus.Error, payload: transactionError, operation: 'deleteInterestRate' });
      }
      return commit(SET_INTERESTRATE, { status: DataContainerStatus.Success, operation: 'deleteInterestRate' });
    },
  },
};
