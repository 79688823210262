export interface FilterObject {
  text: string;
  value: string;
}

export interface FilterInterface {
  investments: {
    byAsset: FilterObject | null,
    byInvestor: FilterObject | null,
    byLoantaker: FilterObject | null,
    byPublished: boolean,
  };
}

/**
 * Returns the initial filter object
 */
export const getInitialFilters = (): FilterInterface => ({
  investments: {
    byAsset: null,
    byInvestor: null,
    byLoantaker: null,
    byPublished: true,
  },
});

export class Filters implements FilterInterface {
  investments = getInitialFilters().investments;
}
