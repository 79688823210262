import { Asset } from '@/models/assets/Asset';
import { Investment } from '@/models/investments/Investment';
import { Investor } from '@/models/users/User';
import firebase from 'firebase/app';

/**
 * getId (par): string
 * Due to permissions errors we need to limit the vuexfire autoresolve feature. That creates an scenario in which object references
 * in a db object can be now an string, firestore reference or the object itself. This method retrieves the id in all the cases
 * @param par relationship field
 * @returns model id
 */
export function getId(par: string | firebase.firestore.DocumentReference | Asset | Investment | Investor): string {
  if (typeof par === 'string') {
    return par.split('/').pop()!;
  }
  return par?.id || '';
}

/**
 * Remove any Firestore document references from an object.
 * @param object T - Object to remove references from.
 * @returns A new object with document references removed.
 */
export function removeDocReferences<T>(object: T): T {
  // Clone the object to avoid overwriting the original data.
  const objectCloned = { ...object };

  // For every field of the object.
  Object.keys(objectCloned as {}).forEach((key): void => {
    if (objectCloned[key] instanceof firebase.firestore.DocumentReference) {
      // If it's a reference, remove it.
      delete objectCloned[key];
    } else if (typeof objectCloned[key] === 'object') {
      // If it's an object, then call the function recursively.
      objectCloned[key] = removeDocReferences(objectCloned[key]);
    }
  });

  return objectCloned;
}
