import { User, Investor, DataChangeRequest } from '@/models/users/User';
import { Vertebra } from '@/store/utils/skeleton';
import { DataContainer, DataContainerInterface, Settings, Counts, Backup } from './Common';
import { Manager, SpecialUser } from './manager/Manager';
import { CurrentManager, CurrentManagerInterface } from './manager/CurrentManager';
import { Filters, FilterInterface } from './Filters';
import { Asset } from './assets/Asset';
import { IdentificationRequest } from './identification-requests/IdentificationRequest';
import { Investment, Payment } from './investments/Investment';
import { Trace } from './tracing/Trace';
import { AssetRepayment } from './assets/Repayments';
import { AssetDeposit } from './assets/Deposit';
import { AssetInvoice } from './assets/Invoices';
import { AssetInterestRate } from './assets/InterestRates';
import { AssetEndDate } from './assets/EndDates';
import { Pescheckv3PescheckDocument } from './identification-requests/pescheck';
import { AssetInterestRateType } from './assets/InterestRateTypes';

/**
 * Vuex state. Some rules:
 * - Datacontainer's type can only be a primitive, since store only allows primitives
 *
 * @see https://vuex.vuejs.org/guide/state.html
 */
export interface State {
  // Firestore native bindings
  manager: Manager | null,

  // Computed/derived data
  currentManager: DataContainerInterface<CurrentManagerInterface>;
  login: DataContainerInterface<any>;
  verifySMS: DataContainerInterface<any>;
  managerStatus: DataContainerInterface<string>;
  managerRole: DataContainerInterface<string>;
  managerRemoval: DataContainerInterface<any>,
  tracing: DataContainerInterface<Trace[]>;
  generalSettings: DataContainerInterface<Settings>;
  securitySettings: DataContainerInterface<string>;
  backups: DataContainerInterface<Backup[]>;
  restoreBackup: DataContainerInterface<any>;
  restoreBackupStatus: DataContainerInterface<any>;
  passwordReset: DataContainerInterface<string>;
  approveSensitiveDataChange: DataContainerInterface<string>;
  rejectSensitiveDataChange: DataContainerInterface<string>;
  notification: DataContainerInterface<string>;
  sendTestEmails: DataContainerInterface<string>;
  updateMainSettings: DataContainerInterface<string>;
  filters: FilterInterface;
  outlineMode: boolean;
  specialUsers: SpecialUser[];
  settings: Settings | null;
  restoration: any[] | null;
  pescheck: Vertebra | null;

  openPayments: Payment[];
  loanPayments: Payment[];

  // The Refactor
  boundInvestments: Investment[];
  boundInvestment: Investment | null;
  boundPayments: Payment[];
  boundPayment: Payment | null;
  boundAssets: Asset[];
  boundAsset: Asset | null;
  boundAssetEdit: Asset | null;
  boundRepayments: AssetRepayment[];
  boundDeposits: AssetDeposit[];
  boundInterestRates: AssetInterestRate[];
  boundEndDates: AssetEndDate[];
  boundInterestRateTypes: AssetInterestRateType[];
  boundAutomatedInvoices: AssetInvoice[];
  boundManualInvoices: AssetInvoice[];
  boundInvoices: AssetInvoice[];
  boundUsers: (User | Investor)[];
  boundUser: User | Investor | null;
  boundPeschecks: Pescheckv3PescheckDocument[];
  boundIdentificationRequests: IdentificationRequest[];
  boundCounts: Counts | null;
  asset: Vertebra | null;
  user: Vertebra | null;
  payment: Vertebra | null;
  identificationRequest: Vertebra | null;
  dataChangeRequests: DataChangeRequest[];
  repayment: Vertebra | null;
  deposit: Vertebra | null;
  interestRate: Vertebra | null;
  endDate: Vertebra | null;
  interestRateType: Vertebra | null;
  invoices: Vertebra | null;
}

export const generateInitialRootState = (): { [key: string]: any } => ({
  // Firestore native bindings
  manager: null,

  // Computed/derived data
  currentManager: new DataContainer<CurrentManager>(),
  login: new DataContainer<any>(),
  verifySMS: new DataContainer<any>(),
  managerStatus: new DataContainer<string>(),
  managerRole: new DataContainer<string>(),
  managerRemoval: new DataContainer<any>(),
  tracing: new DataContainer<Trace[]>(),
  generalSettings: new DataContainer<Settings>(),
  securitySettings: new DataContainer<string>(),
  backups: new DataContainer<Backup[]>(),
  restoreBackup: new DataContainer<string>(),
  restoreBackupStatus: new DataContainer<string>(),
  passwordReset: new DataContainer<string>(),
  approveSensitiveDataChange: new DataContainer<string>(),
  rejectSensitiveDataChange: new DataContainer<string>(),
  sendTestEmails: new DataContainer<string>(),
  updateMainSettings: new DataContainer<string>(),
  notification: new DataContainer<string>(),
  filters: new Filters(),
  outlineMode: false,
  specialUsers: [],
  settings: null,
  restoration: null,
  dataChangeRequests: [],
  openPayments: [],
  loanPayments: [],
  boundInvestments: [],
  boundInvestment: null,
  boundPayments: [],
  boundPayment: null,
  boundAssets: [],
  boundAsset: null,
  boundAssetEdit: null,
  boundRepayments: [],
  boundDeposits: [],
  boundInterestRates: [],
  boundEndDates: [],
  boundInterestRateTypes: [],
  boundInvoices: [],
  boundAutomatedInvoices: [],
  boundManualInvoices: [],
  boundUsers: [],
  boundUser: null,
  boundIdentificationRequests: [],
  boundPeschecks: [],
  boundCounts: [],
});
