import { Vue, Component } from 'vue-property-decorator';
import { State as StateClass } from 'vuex-class';
import { State } from '@/models/State';

@Component
export default class LanguageSidebar extends Vue {
  @StateClass('settings') settings!: State['settings'];

  get currentLang(): string {
    return this.$route.params.lang || 'nl';
  }

  get fullPath(): string {
    const { lang: paramsLang } = this.$route.params;
    const { fullPath } = this.$route;
    // Return if theres no language in the url (edge case)
    if (!paramsLang) {
      return fullPath;
    }
    // Removing the current language from the fullPath
    const extractedUrlLang = fullPath.split('/')[1];
    const startSubstring = `/${extractedUrlLang}`.length;
    return fullPath.substring(startSubstring, fullPath.length);
  }

  get availableLanguages(): string[] {
    return this.settings?.availableLanguages || [];
  }
}
