import firebase from 'firebase/app';
import { Asset } from './Asset';

export enum EndDateStatus {
  Pending = 'pending',
  Applied = 'applied',
}

export interface AssetEndDate {
  id?: string;
  asset: firebase.firestore.DocumentReference | Asset;
  deleted: boolean;
  endDate: firebase.firestore.Timestamp;
  status: EndDateStatus;
  appliedDateTime: firebase.firestore.Timestamp;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime: firebase.firestore.Timestamp;
}
