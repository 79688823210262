import firebase from 'firebase/app';
import { Asset } from './Asset';

export enum InterestRateStatus {
    Pending = 'pending',
    Applied = 'applied',
  }

  export interface AssetInterestRate {
    id?: string;
    asset: firebase.firestore.DocumentReference | Asset;
    deleted: boolean;
    amount: number;
    status: InterestRateStatus;
    appliedDateTime: firebase.firestore.Timestamp;
    createdDateTime: firebase.firestore.Timestamp;
    updatedDateTime: firebase.firestore.Timestamp;
  }
