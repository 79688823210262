import { bloqifyFirestore, firebase } from '@/boot/firebase';
import to from 'await-to-js';

/**
* periodCheck (assetStartDate, appliedDate, generalSettings)
* Check the date to perform a financial operation that affect the monthly invoices (payments, repayments, interest rates...)
* to prevent changing amounts of already generated periods. Return true if the process can continue, false otherwise
* @param assetId string - asset id
* @param appliedDate number - date to apply financial change
*/
export const periodCheck = async (assetId: string, appliedDate: number): Promise<boolean> => {
  const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
  const time = firebase.firestore.Timestamp.fromMillis(appliedDate);

  const [getPeriodInvoicesError, getPeriodInvoices] = await to(assetRef.collection('invoices')
    .where('period.end', '>', time)
    .where('deleted', '==', false)
    .get());
  if (getPeriodInvoicesError) {
    throw getPeriodInvoicesError;
  }

  return getPeriodInvoices!.empty;
};

/**
 * Checks that the coded is being executed in the production enviroment
 * @returns boolean - true production / false dev-staging
 */
export const isProduction = (): boolean => process.env.NODE_ENV === 'production'
  && (process.env.VUE_APP_BLOQIFY_FIREBASE_PROJECT_ID as string)?.includes('production');
