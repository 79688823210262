import firebase from 'firebase/app';
import { FormattedIdin, Idin } from '@/models/identification-requests/idin';
import { Pescheckv3PescheckDocument } from '@/models/identification-requests/pescheck';
import { BusinessIdentification, PrivateIdentification, IdentificationRequestStatus } from '@/models/identification-requests/IdentificationRequest';
import { QuestionnaireAnswers } from '@/models/users/Questionnaire';
import { OppStatus, OppStatusSimplified } from '../opp/Opp';

/**
 * Defining the different roles for managers.
 */
export enum UserRole {
  Superadmin = 'superadmin',
  Admin = 'admin',
  Editor = 'editor',
}

// Workaround for the importing problem as JS object.
export const roles = Object.values(UserRole);

/**
 * This is the banhammer interface. Handles the complete disability
 * of the user to interact with the web application.
 */
export enum UserStatus {
  Disabled = 'disabled',
  Enabled = 'enabled',
}

/**
 * Representing our current KYC methods excluding Pescheck.
 */
export enum KYCMethods {
  Idin = 'idin',
  Private = 'private',
  Business = 'business',
}

/**
 * Representing the types of users currently participating in projects
 */
export enum UserClass {
  Investor = 'investor',
  Loantaker = 'loantaker',
  Notary = 'notary',
}

/**
 * Defining the user's tier and what's his/her account privileges/data.
 */
export enum UserTier {
  Account = 'account',
  Investor = 'investor',
  Trader = 'trader',
  LoanTaker = 'loantaker',
  Notary = 'notary'
}

/**
 * Main User interface. At this form, the user is on the 'account' tier since it does not have advanced
 * to any other higher tier.
 */
export interface User {
  bankAccount?: string;
  oppBankAccount?: string;
  createdDateTime: firebase.firestore.Timestamp;
  customId: number;
  email: string;
  deleted: boolean;
  id?: string;
  riskClass?: string;
  experience?: string;
  walletId?: string;// Opp merchant uid
  oppData?: OppStatus; // Opp merchant status
  oppStatus: OppStatusSimplified; // Opp overall status
  balance?: number; // OPP merchant balance
  combinedInvestors?: string[],
  quickNotes?: string;
  idRequestStatus: IdentificationRequestStatus;
  /**
   * The 'identificationRequest' indicates that there is an iR but not approved yet / rejected
   */
  identificationRequest?: firebase.firestore.DocumentReference | PrivateIdentification | BusinessIdentification;
  /**
   * The 'idin' property here indicates that the user could have a failed / not completed idin transaction.
   */
  idin?: firebase.firestore.DocumentReference | Idin;
  pescheck?: firebase.firestore.DocumentReference | Pescheckv3PescheckDocument;
  status: UserStatus;
  statusMessage?: string;
  tier: UserTier;
  questionnaire?: QuestionnaireAnswers;
  updatedDateTime: firebase.firestore.Timestamp;
  usedBankAccounts?: { value: string, lastUsed: firebase.firestore.Timestamp }[];
  extraEmail?: string[];
  navStatements: string[];
  questionnaireAnswered?: boolean;
  simulationAnswered?: boolean;
  questionnaireDateTime?: firebase.firestore.Timestamp;
  simulationDateTime?: firebase.firestore.Timestamp;
}

/**
 * Basic User plus idin data transformed into legible properties.
 */
export interface IdinInvestor extends Omit<User, 'identificationRequest'>, FormattedIdin {
  kycMethod: KYCMethods.Idin;
  tier: UserTier.Investor | UserTier.LoanTaker | UserTier.Notary;
  idin: firebase.firestore.DocumentReference | Idin;
}

/**
 * Basic User plus the private identification data.
 */
export interface PrivateInvestor extends Omit<User, 'idin' | 'bankAccount'>, Omit<PrivateIdentification, 'status'> {
  kycMethod: KYCMethods.Private;
  tier: UserTier.Investor | UserTier.LoanTaker | UserTier.Notary;
  identificationRequest?: firebase.firestore.DocumentReference | PrivateIdentification;
}

/**
 * Basic User plus the business identification data.
 */
export interface BusinessInvestor extends Omit<User, 'idin' | 'bankAccount'>, Omit<BusinessIdentification, 'status'> {
  kycMethod: KYCMethods.Business;
  tier: UserTier.Investor | UserTier.LoanTaker | UserTier.Notary;
  identificationRequest?: firebase.firestore.DocumentReference | BusinessIdentification;
}

/**
 * Discrimination union between all the investor types. The 'kycMethod' field makes the difference.
 * At this point the User is an Investor and has the `tier === UserTier.Investor`.
 * todo it would be better if this would be correctly typed ie using generics and conditional types
 */
export type Investor = IdinInvestor | PrivateInvestor | BusinessInvestor;

/**
 * Type guard to check if someone is an investor.
 * @param userOrInvestor basic User or Investor interfaces.
 */
export const isInvestor = (userOrInvestor: User | Investor): userOrInvestor is Investor => [UserTier.Investor, UserTier.LoanTaker, UserTier.Notary].includes(userOrInvestor.tier);

/**
 * Data for a bank account change request.
 */
export interface BankAccountChange {
  id?: string;
  bankAccount: string;
  changed: boolean;
  previousBankAccount: string;
  userId: string;
  createdDateTime: firebase.firestore.Timestamp;
}

/**
 * Data for a bank account change request.
 */
export interface DataChangeRequest {
  type: 'name' | 'bankAccount';
  investor: Investor;
  status: 'pending' | 'approved' | 'rejected';
  previousData: { name: string; surname: string; } | { bankAccount: string; }
  newData: { name: string; surname: string; } | { bankAccount: string; }
}

// Asset Converter created in order to just load from firestore the required fields
export class InvestorConverter {
  constructor(
    readonly customId: string,
    readonly createdDateTime: firebase.firestore.Timestamp,
    readonly email: string,
    readonly tier: string,
    readonly status: string,
    readonly name: string,
    readonly surname: boolean,
    readonly companyId: string,
    readonly oppData: OppStatus,
  ) {
    this.customId = customId;
    this.createdDateTime = createdDateTime;
    this.email = email;
    this.tier = tier;
    this.status = status;
    this.name = name;
    this.surname = surname;
    this.companyId = companyId;
    this.oppData = oppData;
  }
}

export const investorConverter = {
  toFirestore(investor): firebase.firestore.DocumentData {
    return {
      customId: investor.customId,
      createdDateTime: investor.createdDateTime,
      email: investor.email,
      tier: investor.tier,
      status: investor.status,
      name: investor.name,
      surname: investor.surname,
      companyId: investor.companyId,
      oppData: investor.oppData,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): InvestorConverter {
    const data = snapshot.data(options)!;
    return new InvestorConverter(
      data.customId,
      data.createdDateTime,
      data.email,
      data.tier,
      data.status,
      data.name,
      data.surname,
      data.companyId,
      data.oppData,
    );
  },
};

export type BusinessIdentificationImages = keyof Pick<BusinessInvestor, 'passport' | 'kvkImage'>;

export interface UserFile {
  name: string,
  file: File,
}
