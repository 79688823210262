export const enum ComplianceStatus {
  unverified = 'unverified',
  pending = 'pending',
  verified = 'verified',
}

export const enum MerchantStatus {
  new = 'new',
  pending = 'pending',
  live = 'live',
  suspended = 'suspended',
  terminated = 'terminated',
  blocked = 'blocked',
}

export interface OppStatus {
  merchantStatus: MerchantStatus;
  complianceStatus: ComplianceStatus;
  complianceUrl: string;
  requirements: string[];
}

export enum AssetPaymentMethod {
  None = 'none',
  Mandate = 'mandate',
  DirectLink = 'directLink',
  Deposit = 'deposit',
  Wallet = 'wallet',
}

export enum MandateStatus {
  created = 'created',
  pending = 'pending',
  completed = 'completed',
  cancelled = 'cancelled',
  failed = 'failed',
  expired = 'expired',
  revoked = 'revoked',
}

export const enum PaymentMethod {
  ideal = 'ideal',
  bcmc = 'bcmc',
  sepa = 'sepa',
  creditcard = 'creditcard',
  'sofort-banking' = 'sofort-banking',
  mybank = 'mybank',
  giropay = 'giropay',
}

export enum OppStatusSimplified {
  ERROR = 'error',
  UNVERIFIED = 'unverified',
  LIVE = 'live',
  PENDING = 'pending',
  NONE = 'none',
}
