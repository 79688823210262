import { MutationTree } from 'vuex';
import { vuexfireMutations } from 'vuexfire';
import firebase from 'firebase/app';
import { DataContainerStatus } from '@/models/Common';
import { State } from '@/models/State';
import { CurrentManager } from '@/models/manager/CurrentManager';
import { Trace } from '@/models/tracing/Trace';
import { getInitialFilters } from '@/models/Filters';
import { Investor } from '@/models/users/User';
import { Asset } from '@/models/assets/Asset';
import { IdentificationRequest } from '@/models/identification-requests/IdentificationRequest';
import { Pescheckv3PescheckDocument } from '@/models/identification-requests/pescheck';

/**
 * Update Firestore user profile field with value.
 */
function updateProfileField(state: State, fieldName: string, value: string): void {
  // If not set yet, do not update,
  // since binding was not ready apparently
  if (state.manager !== null) {
    // @ts-ignore
    state.manager.profile[fieldName] = value;
  }
}

export default <MutationTree<State>>{
  /**
   * Resetting bound slice of state
   * @param state State
   * @param context Name of the slice of state and payload to store
   */
  resetSliceState(state, { name, payload }: { name: keyof State, payload: [] | null }): void {
    // @ts-ignore
    state[name] = payload;
  },

  setBoundUsers(state, boundUsers: Investor[]): void {
    state.boundUsers = boundUsers;
  },

  setBoundAssets(state, boundAssets: Asset[]): void {
    state.boundAssets = boundAssets;
  },

  setBoundPeschecks(state, boundPeschecks: Pescheckv3PescheckDocument[]): void {
    state.boundPeschecks = boundPeschecks;
  },

  /**
   * Outline toggle.
   */

  enableOutlineMode(state): void {
    state.outlineMode = true;
  },

  disableOutlineMode(state): void {
    state.outlineMode = false;
  },

  toggleOutlineMode(state): void {
    state.outlineMode = !state.outlineMode;
  },

  /**
   * We need to set current user to be able
   * to always have the user available in
   * the state.
   *
   * @see ./vue.ts
   */
  setcurrentManager(state, manager: CurrentManager): void {
    state.currentManager.name = 'setcurrentManager';
    state.currentManager.payload = manager;
    state.currentManager.status = DataContainerStatus.Success;
    state.currentManager.error = undefined;
  },

  /**
   * Login.
   */

  loginProcessing(state, { name }: { name: string }): void {
    state.login.name = name;
    state.login.payload = null;
    state.login.status = DataContainerStatus.Processing;
    state.login.error = undefined;
  },

  loginError(state, { name, error }: { name: string, error: Error }): void {
    state.login.name = name;
    state.login.payload = null;
    state.login.status = DataContainerStatus.Error;
    state.login.error = error;
  },

  loginSuccess(state, { name, ...payload }: any): void {
    state.login.name = name;
    state.login.payload = payload;
    state.login.status = DataContainerStatus.Success;
    state.login.error = undefined;
  },

  /**
   * Verificate SMS
   */

  verifySMSProcessing(state): void {
    state.verifySMS.name = 'verifySMSProcessing';
    state.verifySMS.payload = null;
    state.verifySMS.status = DataContainerStatus.Processing;
    state.verifySMS.error = undefined;
  },

  verifySMSError(state, { name, error }: { name: string, error: Error }): void {
    state.verifySMS.name = name;
    state.verifySMS.payload = null;
    state.verifySMS.status = DataContainerStatus.Error;
    state.verifySMS.error = error || 'Something went wrong.';
  },

  verifySMSSuccess(state, payload: { multiFactorAssertion: firebase.auth.MultiFactorAssertion }): void {
    state.verifySMS.name = 'verifySMSSuccess';
    state.verifySMS.payload = payload;
    state.verifySMS.status = DataContainerStatus.Success;
    state.verifySMS.error = undefined;
  },

  /**
   * Logout.
   */

  logoutError(state, logOutError: string): void {
    state.currentManager.name = 'logoutError';
    state.currentManager.payload = null;
    state.currentManager.status = DataContainerStatus.Error;
    state.currentManager.error = logOutError || 'Something went wrong.';
  },

  logoutProcessing(state): void {
    state.currentManager.name = 'logoutProcessing';
    state.currentManager.payload = null;
    state.currentManager.status = DataContainerStatus.Processing;
    state.currentManager.error = undefined;
  },

  logoutSuccess(state): void {
    state.currentManager.name = 'logoutSuccess';
    state.currentManager.payload = null;
    state.currentManager.status = DataContainerStatus.Success;
    state.currentManager.error = undefined;
  },

  /**
   * Password reset.
   */

  sendPasswordResetEmailError(state, error: string): void {
    state.passwordReset.name = 'sendPasswordResetEmailError';
    state.passwordReset.payload = null;
    state.passwordReset.status = DataContainerStatus.Error;
    state.passwordReset.error = error || 'Something went wrong.';
  },

  sendPasswordResetEmailProcessing(state): void {
    state.passwordReset.name = 'sendPasswordResetEmailProcessing';
    state.passwordReset.payload = null;
    state.passwordReset.status = DataContainerStatus.Processing;
    state.passwordReset.error = undefined;
  },

  sendPasswordResetEmailSuccess(state): void {
    state.passwordReset.name = 'sendPasswordResetEmailSuccess';
    state.passwordReset.payload = null;
    state.passwordReset.status = DataContainerStatus.Success;
    state.passwordReset.error = undefined;
  },

  /**
   * Get Tracing.
   */

  getTracingProcessing(state): void {
    state.tracing.name = 'getTracingProcessing';
    state.tracing.payload = null;
    state.tracing.status = DataContainerStatus.Processing;
    state.tracing.error = undefined;
  },

  getTracingError(state, error: any): void {
    state.tracing.name = 'getTracingError';
    state.tracing.payload = null;
    state.tracing.status = DataContainerStatus.Error;
    state.tracing.error = String(error || 'Something went wrong.');
  },

  getTracingSuccess(state, tracing: Trace[]): void {
    state.tracing.name = 'getTracingSuccess';
    state.tracing.payload = tracing;
    state.tracing.status = DataContainerStatus.Success;
    state.tracing.error = undefined;
    state.tracing.length = tracing.length;
  },

  /**
   * Toggle user status (enabled/disabled).
   */

  removeManagerProcessing(state): void {
    state.managerRemoval.name = 'removeManagerProcessing';
    state.managerRemoval.status = DataContainerStatus.Processing;
    state.managerRemoval.error = undefined;
  },

  removeManagerError(state, error?: string): void {
    state.managerRemoval.name = 'removeManagerError';
    state.managerRemoval.payload = null;
    state.managerRemoval.status = DataContainerStatus.Error;
    state.managerRemoval.error = error || 'Something went wrong.';
  },

  removeManagerSuccess(state, data: any): void {
    state.managerRemoval.name = 'removeManagerSuccess';
    state.managerRemoval.payload = data;
    state.managerRemoval.status = DataContainerStatus.Success;
    state.managerRemoval.error = undefined;
  },

  /**
   * Set manager role.
   */

  setManagerRoleProcessing(state, uid: string): void {
    state.managerRole.name = 'setManagerRoleProcessing';
    state.managerRole.payload = uid;
    state.managerRole.status = DataContainerStatus.Processing;
    state.managerRole.error = undefined;
  },

  setManagerRoleError(state, error?: string): void {
    state.managerRole.name = 'setManagerRoleError';
    state.managerRole.payload = null;
    state.managerRole.status = DataContainerStatus.Error;
    state.managerRole.error = error || 'Something went wrong.';
  },

  setManagerRoleSuccess(state, data: any): void {
    state.managerRole.name = 'setManagerRoleSuccess';
    state.managerRole.payload = data;
    state.managerRole.status = DataContainerStatus.Success;
    state.managerRole.error = undefined;
  },

  /**
   * General settings.
   */

  updateProfileName(state, name: string): void {
    updateProfileField(state, 'name', name);
  },

  updateProfileTelephone(state, telephone: string): void {
    updateProfileField(state, 'telephone', telephone);
  },

  updateProfileSkype(state, skype: string): void {
    updateProfileField(state, 'skype', skype);
  },

  updateProfileSlack(state, slack: string): void {
    updateProfileField(state, 'slack', slack);
  },

  updateProfileTeam(state, team: string): void {
    updateProfileField(state, 'team', team);
  },

  updateProfileBiography(state, biography: string): void {
    updateProfileField(state, 'biography', biography);
  },

  submitGeneralSettingsProcessing(state): void {
    state.generalSettings.name = 'submitGeneralSettingsProcessing';
    state.generalSettings.payload = null;
    state.generalSettings.status = DataContainerStatus.Processing;
    state.generalSettings.error = undefined;
  },

  submitGeneralSettingsError(state, error: string | undefined): void {
    state.generalSettings.name = 'submitGeneralSettingsError';
    state.generalSettings.payload = null;
    state.generalSettings.status = DataContainerStatus.Error;
    state.generalSettings.error = error || 'Something went wrong.';
  },

  submitGeneralSettingsSuccess(state): void {
    state.generalSettings.name = 'submitGeneralSettingsSuccess';
    state.generalSettings.payload = null;
    state.generalSettings.status = DataContainerStatus.Success;
    state.generalSettings.error = undefined;
  },

  /**
   * Security settings.
   */

  changePasswordProcessing(state): void {
    state.securitySettings.name = 'changePasswordProcessing';
    state.securitySettings.payload = null;
    state.securitySettings.status = DataContainerStatus.Processing;
    state.securitySettings.error = undefined;
  },

  changePasswordError(state, error: string | undefined): void {
    state.securitySettings.name = 'changePasswordError';
    state.securitySettings.payload = null;
    state.securitySettings.status = DataContainerStatus.Error;
    state.securitySettings.error = error || 'Something went wrong.';
  },

  changePasswordSuccess(state): void {
    state.securitySettings.name = 'changePasswordSuccess';
    state.securitySettings.payload = null;
    state.securitySettings.status = DataContainerStatus.Success;
    state.securitySettings.error = undefined;
  },

  /**
   * Start restore backup process.
   */
  fetchBackupsProcessing(state): void {
    state.backups.name = 'fetchBackupsProcessing';
    state.backups.payload = null;
    state.backups.status = DataContainerStatus.Processing;
    state.backups.error = undefined;
  },

  fetchBackupsError(state, error: string | undefined): void {
    state.backups.name = 'fetchBackupsError';
    state.backups.payload = null;
    state.backups.status = DataContainerStatus.Error;
    state.backups.error = error || 'Something went wrong.';
  },

  fetchBackupsSuccess(state, data: any): void {
    state.backups.name = 'fetchBackupsSuccess';
    state.backups.payload = data;
    state.backups.status = DataContainerStatus.Success;
    state.backups.error = undefined;
  },

  /**
   * Start restore backup process.
   */

  restoreBackupProcessing(state): void {
    state.restoreBackup.name = 'restoreBackupProcessing';
    state.restoreBackup.payload = null;
    state.restoreBackup.status = DataContainerStatus.Processing;
    state.restoreBackup.error = undefined;
  },

  restoreBackupError(state, error: string | undefined): void {
    state.restoreBackup.name = 'restoreBackupError';
    state.restoreBackup.payload = null;
    state.restoreBackup.status = DataContainerStatus.Error;
    state.restoreBackup.error = error || 'Something went wrong.';
  },

  restoreBackupSuccess(state, payload: { id: string }): void {
    state.restoreBackup.name = 'restoreBackupSuccess';
    state.restoreBackup.payload = payload;
    state.restoreBackup.status = DataContainerStatus.Success;
    state.restoreBackup.error = undefined;
  },

  /**
   * Fetch restore backup process status.
   */

  restoreBackupStatusProcessing(state): void {
    state.restoreBackupStatus.name = 'restoreBackupStatusProcessing';
    state.restoreBackupStatus.payload = null;
    state.restoreBackupStatus.status = DataContainerStatus.Processing;
    state.restoreBackupStatus.error = undefined;
  },

  restoreBackupStatusError(state, error: string | undefined): void {
    state.restoreBackupStatus.name = 'restoreBackupStatusError';
    state.restoreBackupStatus.payload = null;
    state.restoreBackupStatus.status = DataContainerStatus.Error;
    state.restoreBackupStatus.error = error || 'Something went wrong.';
  },

  restoreBackupStatusSuccess(state, payload): void {
    state.restoreBackupStatus.name = 'restoreBackupStatusSuccess';
    state.restoreBackupStatus.payload = payload;
    state.restoreBackupStatus.status = DataContainerStatus.Success;
    state.restoreBackupStatus.error = undefined;
  },

  /**
   * Approve sensitive data change.
   */

  approveSensitiveDataChangeProcessing(state): void {
    state.approveSensitiveDataChange.name = 'approveSensitiveDataChangeProcessing';
    state.approveSensitiveDataChange.payload = null;
    state.approveSensitiveDataChange.status = DataContainerStatus.Processing;
    state.approveSensitiveDataChange.error = undefined;
  },

  approveSensitiveDataChangeError(state, error: string | undefined): void {
    state.approveSensitiveDataChange.name = 'approveSensitiveDataChangeError';
    state.approveSensitiveDataChange.payload = null;
    state.approveSensitiveDataChange.status = DataContainerStatus.Error;
    state.approveSensitiveDataChange.error = error || 'Something went wrong.';
  },

  approveSensitiveDataChangeSuccess(state): void {
    state.approveSensitiveDataChange.name = 'approveSensitiveDataChangeSuccess';
    state.approveSensitiveDataChange.payload = null;
    state.approveSensitiveDataChange.status = DataContainerStatus.Success;
    state.approveSensitiveDataChange.error = undefined;
  },

  /**
   * Decline sensitive data change.
   */

  rejectSensitiveDataChangeProcessing(state): void {
    state.rejectSensitiveDataChange.name = 'rejectSensitiveDataChangeProcessing';
    state.rejectSensitiveDataChange.payload = null;
    state.rejectSensitiveDataChange.status = DataContainerStatus.Processing;
    state.rejectSensitiveDataChange.error = undefined;
  },

  rejectSensitiveDataChangeError(state, error: string | undefined): void {
    state.rejectSensitiveDataChange.name = 'rejectSensitiveDataChangeError';
    state.rejectSensitiveDataChange.payload = null;
    state.rejectSensitiveDataChange.status = DataContainerStatus.Error;
    state.rejectSensitiveDataChange.error = error || 'Something went wrong.';
  },

  rejectSensitiveDataChangeSuccess(state): void {
    state.rejectSensitiveDataChange.name = 'rejectSensitiveDataChangeSuccess';
    state.rejectSensitiveDataChange.payload = null;
    state.rejectSensitiveDataChange.status = DataContainerStatus.Success;
    state.rejectSensitiveDataChange.error = undefined;
  },

  sendNotificationProcessing(state): void {
    state.notification.name = 'sendNotificationProcessing';
    state.notification.payload = null;
    state.notification.status = DataContainerStatus.Processing;
    state.notification.error = undefined;
  },

  sendNotificationError(state, error: string | undefined): void {
    state.notification.name = 'sendNotificationError';
    state.notification.payload = null;
    state.notification.status = DataContainerStatus.Error;
    state.notification.error = error || 'Something went wrong.';
  },

  sendNotificationSuccess(state): void {
    state.notification.name = 'sendNotificationSuccess';
    state.notification.payload = null;
    state.notification.status = DataContainerStatus.Success;
    state.notification.error = undefined;
  },

  sendTestEmailsProcessing(state): void {
    state.sendTestEmails.name = 'sendTestEmailsProcessing';
    state.sendTestEmails.payload = null;
    state.sendTestEmails.status = DataContainerStatus.Processing;
    state.sendTestEmails.error = undefined;
  },

  sendTestEmailsError(state, error: string | undefined): void {
    state.sendTestEmails.name = 'sendTestEmailsError';
    state.sendTestEmails.payload = null;
    state.sendTestEmails.status = DataContainerStatus.Error;
    state.sendTestEmails.error = error || 'Something went wrong.';
  },

  sendTestEmailsSuccess(state): void {
    state.sendTestEmails.name = 'sendTestEmailsSuccess';
    state.sendTestEmails.payload = null;
    state.sendTestEmails.status = DataContainerStatus.Success;
    state.sendTestEmails.error = undefined;
  },

  updateMainSettingsProcessing(state): void {
    state.updateMainSettings.name = 'updateMainSettingsProcessing';
    state.updateMainSettings.payload = null;
    state.updateMainSettings.status = DataContainerStatus.Processing;
    state.updateMainSettings.error = undefined;
  },

  updateMainSettingsError(state, error: string | undefined): void {
    state.updateMainSettings.name = 'updateMainSettingsError';
    state.updateMainSettings.payload = null;
    state.updateMainSettings.status = DataContainerStatus.Error;
    state.updateMainSettings.error = error || 'Something went wrong.';
  },

  updateMainSettingsSuccess(state): void {
    state.updateMainSettings.name = 'updateMainSettingsSuccess';
    state.updateMainSettings.payload = null;
    state.updateMainSettings.status = DataContainerStatus.Success;
    state.updateMainSettings.error = undefined;
  },

  /**
   * Filter mutations
   */

   setFilter(
     state,
     { collection, field, value }: { collection: string, field: string, value: string | boolean },
   ): void {
     state.filters = {
       ...state.filters,
       [collection]: {
         ...state.filters[collection],
         [field]: value,
       },
     };
   },

  resetFilters(
    state,
    { collection }: { collection: string },
  ): void {
    state.filters = {
      ...state.filters,
      [collection]: getInitialFilters()[collection],
    };
  },

  ...vuexfireMutations,
};
