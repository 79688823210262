import { Module } from 'vuex';
import { State } from '@/models/State';
import { bloqifyFunctions } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { AssetAccounts } from '@/models/assets/Deposit';
import to from 'await-to-js';
import { Vertebra, generateState, mutateState } from '../utils/skeleton';

const SET_DEPOSIT = 'SET_DEPOSIT';

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_DEPOSIT](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addFunds (
      { commit },
      { assetId, amount, account }: { assetId: string, amount: number, account: AssetAccounts },
    ): Promise<void> {
      commit(SET_DEPOSIT, { status: DataContainerStatus.Processing, operation: 'addFunds' });

      const [depositTransactionError, depositTransaction] = await to(bloqifyFunctions.httpsCallable('depositOperations')({
        amount,
        assetId,
        account,
        action: 'addFunds',
      }));
      if (depositTransactionError) {
        return commit(SET_DEPOSIT, { status: DataContainerStatus.Error, payload: depositTransactionError, operation: 'addFunds' });
      }

      return commit(SET_DEPOSIT, { status: DataContainerStatus.Success, operation: 'addFunds' });
    },
    async payoutFunds (
      { commit },
      { assetId, amount, recipientId, account }: { assetId: string, amount: number, recipientId: string, account: AssetAccounts },
    ): Promise<void> {
      commit(SET_DEPOSIT, { status: DataContainerStatus.Processing, operation: 'payoutFunds' });

      const [depositTransactionError, depositTransaction] = await to(bloqifyFunctions.httpsCallable('depositOperations')({
        amount,
        assetId,
        recipientId,
        account,
        action: 'payoutFunds',
      }));
      if (depositTransactionError) {
        return commit(SET_DEPOSIT, { status: DataContainerStatus.Error, payload: depositTransactionError, operation: 'payoutFunds' });
      }

      return commit(SET_DEPOSIT, { status: DataContainerStatus.Success, operation: 'payoutFunds' });
    },
    async transferFunds (
      { commit },
      { assetId, amount, source, target }: { assetId: string, amount: number, source: AssetAccounts, target: AssetAccounts },
    ): Promise<void> {
      commit(SET_DEPOSIT, { status: DataContainerStatus.Processing, operation: 'transferFunds' });

      const [depositTransactionError, depositTransaction] = await to(bloqifyFunctions.httpsCallable('depositOperations')({
        amount,
        assetId,
        source,
        target,
        action: 'transfer',
      }));
      if (depositTransactionError) {
        return commit(SET_DEPOSIT, { status: DataContainerStatus.Error, payload: depositTransactionError, operation: 'addDeposit' });
      }

      return commit(SET_DEPOSIT, { status: DataContainerStatus.Success, operation: 'transferFunds' });
    },
    async resendCode (
      { commit },
      { assetId, depositId }: { assetId: string, depositId: string },
    ): Promise<void> {
      commit(SET_DEPOSIT, { status: DataContainerStatus.Processing, operation: 'resendCode' });

      const [depositTransactionError, depositTransaction] = await to(bloqifyFunctions.httpsCallable('depositOperations')({
        assetId,
        depositId,
        action: 'resend',
      }));
      if (depositTransactionError) {
        return commit(SET_DEPOSIT, { status: DataContainerStatus.Error, payload: depositTransactionError, operation: 'resendCode' });
      }

      return commit(SET_DEPOSIT, { status: DataContainerStatus.Success, operation: 'resendCode' });
    },
    async acceptOperation (
      { commit },
      { assetId, depositId, code }: { assetId: string, depositId: string, code: string },
    ): Promise<void> {
      commit(SET_DEPOSIT, { status: DataContainerStatus.Processing, operation: 'acceptOperation' });

      const [depositTransactionError, depositTransaction] = await to(bloqifyFunctions.httpsCallable('depositOperations')({
        code,
        assetId,
        depositId,
        action: 'accept',
      }));
      if (depositTransactionError) {
        return commit(SET_DEPOSIT, { status: DataContainerStatus.Error, payload: depositTransactionError, operation: 'acceptOperation' });
      }

      return commit(SET_DEPOSIT, { status: DataContainerStatus.Success, operation: 'acceptOperation' });
    },
    async cancelOperation (
      { commit },
      { assetId, depositId }: { assetId: string, depositId: string },
    ): Promise<void> {
      commit(SET_DEPOSIT, { status: DataContainerStatus.Processing, operation: 'cancelOperation' });

      const [depositTransactionError, depositTransaction] = await to(bloqifyFunctions.httpsCallable('depositOperations')({
        assetId,
        depositId,
        action: 'cancel',
      }));
      if (depositTransactionError) {
        return commit(SET_DEPOSIT, { status: DataContainerStatus.Error, payload: depositTransactionError, operation: 'cancelOperation' });
      }

      return commit(SET_DEPOSIT, { status: DataContainerStatus.Success, operation: 'cancelOperation' });
    },
  },
};
